export const useIndexStore = defineStore('index', {
  state: () => ({
    showDialog: false,
    firstLogin: false,
    statusCode: 0,
    msg: '',
    loading: false,
    notiRefer: 0
  }),

  getters: {
    ShowDialog: (state) => state.showDialog,
    FirstLogin: (state) => state.firstLogin,
    StatusCode: (state) => state.statusCode,
    Msg: (state) => state.msg,
    Loading: (state) => state.loading,
    NotiRefer: (state) => state.notiRefer
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIndexStore, import.meta.hot))
}